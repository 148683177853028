import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const NorthwesternMedicineLandingPage = () => (
  <PhysicianLandingPage
    physician="Northwestern Medicine"
    institution="Northwestern Medicine"
    referralCode="NORTHWESTERN"
    physicianURL="https://www.nm.org/locations/urology-chicago"
  />
)

export default NorthwesternMedicineLandingPage
